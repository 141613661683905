import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import AudioPage from './audioPage';
import AudioPage1 from './audioPage1';

function App() {
  return (
    <div>
      <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path='/omRTKzFoyvNlBByUsaRVQIqnjcxiABvg' element={<AudioPage />} />
        <Route path='/omRTKzFoyvNlBByUsaRVQIqnjcxiABvgcjsvbsjbvjkdsbvsdb' element={<AudioPage1 />} />
      </Routes>
    </Router>
    </div>
  );
}

export default App;