import React, { useEffect, useRef } from 'react';
import './Home.css'
import logo from './images/logo.png'
import product1 from './images/product1.jpg'
import product2 from './images/product2.jpg'
import product3 from './images/product3.jpg'
import ScrollReveal from 'scrollreveal';

function Home() {

    useEffect(() => {
        // Create a new instance of ScrollReveal
        const sr = ScrollReveal({
          duration: 3000, // Animation duration in milliseconds
          reset: true, // Reset reveal animations when they are no longer in the viewport
          delay: 500, // Delay between revealing elements in milliseconds
          distance: '20px', // Distance the element will travel in the reveal animation
        });
    
        // Define the elements to reveal
        const elementsToReveal = ['.product-list'];
    
        // Loop through each element and apply the reveal animation
        elementsToReveal.forEach((selector) => {
          sr.reveal(selector);
        });
    
        // Cleanup the ScrollReveal instance when the component unmounts
        return () => {
          sr.destroy();
        };
      }, []);

    return(
        <div style={{fontFamily: 'Maven Pro'}}>
            <div className='background-home'>
                <div className='background-div'>
                    <img src={logo} alt="" className='background-image' />
                </div>
                <div className='background-text'>
                    <h2 className='text-back-1'>Harmonizing Tradition with Innovation for Healthier Tomorrows.</h2>
                </div>
            </div>
            <div className='upcoming-product-div'>
                <h1 className='upcoming-heading'>Our Upcoming Products</h1>
                <div className='product-list'>
                    <img src={product1} className='product-img-1'/>
                    <img src={product2} className='product-img'/>
                    <img src={product3} className='product-img'/>
                </div>
            </div>
            <div className='upcoming-announce'>
                <p>Exciting updates are coming your way! Keep an eye out for our forthcoming website launch.</p>
            </div>
        </div>
    )
}

export default Home