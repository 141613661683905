import React, { useRef, useState, useEffect } from 'react';
import AudioClip from './images/Jazz-Master-2B.mp3';

const AudioPage1 = () => {
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);

    const togglePlay = () => {
        const audio = audioRef.current;
        if (audio.paused) {
            audio.play();
            setIsPlaying(true);
        } else {
            audio.pause();
            setIsPlaying(false);
        }
    };

    useEffect(() => {
        const audio = audioRef.current;

        const updateCurrentTime = () => {
            setCurrentTime(audio.currentTime);
        };

        const setAudioDuration = () => {
            setDuration(audio.duration);
        };

        // Listen for time updates and metadata load
        audio.addEventListener('timeupdate', updateCurrentTime);
        audio.addEventListener('loadedmetadata', setAudioDuration);

        // Cleanup event listeners on component unmount
        return () => {
            audio.removeEventListener('timeupdate', updateCurrentTime);
            audio.removeEventListener('loadedmetadata', setAudioDuration);
        };
    }, []);

    // Function to format time in minutes:seconds
    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60).toString().padStart(2, '0');
        return `${minutes}:${seconds}`;
    };

    // Handle slider change (drag) to seek audio
    const handleSliderChange = (e) => {
        const audio = audioRef.current;
        const newTime = e.target.value;
        audio.currentTime = newTime;
        setCurrentTime(newTime);
    };

    return (
        <div style={{ textAlign: 'center', marginTop: '100px' }}>
            <h1 style={{ fontFamily: 'Maven Pro', fontWeight: '1000', marginBottom: '0.6em' }}>
                Listen to the Audio
            </h1>
            {/* Hidden native controls */}
            <audio ref={audioRef} controls={false}>
                <source src={AudioClip} type="audio/mpeg" />
                Your browser does not support the audio element.
            </audio>
            {/* Custom control buttons */}
            <div>
                <button onClick={togglePlay} style={buttonStyle}>
                    {isPlaying ? 'Pause' : 'Play'}
                </button>
            </div>
            {/* Slider to seek audio */}
            <input
                type="range"
                min="0"
                max={duration}
                value={currentTime}
                onChange={handleSliderChange}
                style={sliderStyle}
            />
            {/* Display current time and total duration */}
            <div style={{ fontFamily: 'Maven Pro', fontWeight: '600', marginTop: '10px' }}>
                Current Time: {formatTime(currentTime)} / {formatTime(duration)}
            </div>
            <style>
                {`
                    input[type="range"] {
                        -webkit-appearance: none;
                        width: 80%;
                        margin-top: 20px;
                    }

                    input[type="range"]::-webkit-slider-thumb {
                        -webkit-appearance: none;
                        width: 15px;
                        height: 15px;
                        border-radius: 50%;
                        background: #4CAF50; /* Match button color */
                        cursor: pointer;
                        transition: background 0.3s ease-in-out;
                    }

                    input[type="range"]::-webkit-slider-runnable-track {
                        width: 100%;
                        height: 15px;
                        cursor: pointer;
                        background: #ddd; /* Track color */
                        border-radius: 5px;
                    }

                    input[type="range"]:focus {
                        outline: none;
                    }

                    input[type="range"]::-moz-range-thumb {
                        width: 15px;
                        height: 15px;
                        border-radius: 50%;
                        background: #4CAF50; /* Match button color */
                        cursor: pointer;
                        transition: background 0.3s ease-in-out;
                    }

                    input[type="range"]::-moz-range-track {
                        width: 100%;
                        height: 5px;
                        cursor: pointer;
                        background: #ddd; /* Track color */
                        border-radius: 5px;
                    }
                `}
            </style>
        </div>
    );
};

const buttonStyle = {
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    margin: '20px 0',
    fontFamily: 'Maven Pro',
    fontWeight: '600',
    border: 'none',
    borderRadius: '5px',
    backgroundColor: '#4CAF50',
    color: 'white',
};

const sliderStyle = {
    width: '50%',
    marginTop: '5px',
    // Basic styling only for width and margin-top
};

export default AudioPage1;
